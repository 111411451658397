import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import user from "../../assets/user.png";
import { getUserListofORG } from "../../services/loggedInApp";
import ProfileNav from "../Profile/ProfileNav";
import moment from "moment";
import Modal from "react-responsive-modal";
import Dropdown from "react-dropdown";
import {
  Created_app_CV,
  Delete_app_CV,
  Edit_app_CV,
  FillAllFields,
  ServerError,
  UpdatedSuccessfully,
} from "../NotificationToast";
import { ToastContainer } from "react-toastify";
import TimezoneSelect from "react-timezone-select";
import GTable from "../GTable";
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";

export default function UserORGPage() {
  const history = useHistory();
  const location = useLocation();
  const locState = location.state;
  //   console.log('locStateUserPage', locState)

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [orgsUserList, setOrgsUserList] = useState([]);
  const [role, setRole] = useState("");
  const [timezone, setTimeZone] = useState({});

  const [openEditModel, setOpenEditModel] = useState(false);
  const [openCreate_userDataCv_Modal, setOpenCreate_userDataCv_Modal] =
    useState(false);
  const [openEdit_userDataCV_Modal, setOpenEdit_UserDataCV_Modal] =
    useState(false);
  const [openDelete_userDataCV_Modal, setOpenDelete_UserDataCV_Modal] =
    useState(false);
  const [placeHolder_valueCV, setPlaceHolder_valueCV] = useState("");
  const [get_userdataCV, setGet_userdataCV] = useState([]);
  const [delete_userDataCv_Value, setDelete_userDataCv_Value] = useState("");
  const [userData_cv_keyValue, setUserData_cv_keyValue] = useState("");
  const [userData_cv_Value, setUserData_cv_Value] = useState("");

  const rolesOptions = ["admin", "Member"];
  const strivedRolesOptions = [
    "site-admin",
    "district-admin",
    "teacher",
    "school-admin",
  ];

  const handleRoleSelect = (role) => {
    setRole(role.value);
  };

  const getUserlistOrg = () => {
    getUserListofORG(locState.appId, locState.orgId).then((res) => {
      if (res.data.data == "") {
        setOrgsUserList(undefined);
      } else {
        setOrgsUserList(res.data.data);
      }
    });
  };

  const editUserInfo = (e) => {
    e.preventDefault();
    fetch("https://api.trypadlock.com/profile", {
      method: "PUT",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        password: password,
        avatar: "",
        user_uuid: locState.user_id,
        time_zone: timezone,
        role: role,
        appId: locState.appId,
        orgId: locState.orgId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "data updated succesfully") {
          setOpenEditModel(false);
          UpdatedSuccessfully();
          getUserlistOrg();
        } else {
          ServerError();
        }
      });
  };

  useEffect(() => {
    getUserlistOrg();
    get_userData_CV();
  }, []);

  const onCloseModel = () => {
    setOpenEditModel(false);
    setOpenEdit_UserDataCV_Modal(false);
    setOpenCreate_userDataCv_Modal(false);
    setOpenDelete_UserDataCV_Modal(false);
    setDelete_userDataCv_Value("");
    setUserData_cv_Value("");
    setUserData_cv_keyValue("");
  };

  const handleNavigation = (e, email, userId, type) => {
    e.preventDefault();
    history.push({
      pathname: `/${locState.appName}/${locState.orgName}/magicLink`,
      state: {
        orgId: locState.orgId,
        appName: locState.appName,
        appId: locState.appId,
        userEmail: email,
      },
    });
  };

  const userProfile = orgsUserList.find(
    (user) => user.user_id === locState.user_id
  );
  // console.log('san', userProfile)

  const edit_userData_CV_Modal = (user_info) => {
    setPlaceHolder_valueCV(user_info);
    setOpenEdit_UserDataCV_Modal(true);
  };

  const delete_userData_CV_Modal = (user_info) => {
    setDelete_userDataCv_Value(user_info);
    setOpenDelete_UserDataCV_Modal(true);
  };

  const get_userData_CV = (userId) => {
    fetch(
      `https://api.trypadlock.com/profile/user_data?user_uuid=${locState.user_id}`,
      {
        method: "GET",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "" || data.status == undefined) {
          console.log("Error in getting user data");
        } else {
          setGet_userdataCV(data.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const create_userData_CV = (e) => {
    e.preventDefault();
    if (userData_cv_keyValue == "" || userData_cv_Value == "") {
      FillAllFields();
    } else {
      fetch("https://api.trypadlock.com/profile/user_data", {
        method: "POST",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_uuid: locState.user_id,
          name: userData_cv_keyValue,
          main_key: userData_cv_Value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "user got added with the value and key") {
            get_userData_CV();
            setOpenCreate_userDataCv_Modal(false);
            Created_app_CV();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const edit_userData_CV = (e) => {
    e.preventDefault();
    if (userData_cv_Value == "") {
      FillAllFields();
    } else if (placeHolder_valueCV.key == "") {
      ServerError();
    } else {
      fetch("https://api.trypadlock.com/profile/user_data", {
        method: "POST",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_uuid: locState.user_id,
          name: placeHolder_valueCV.key,
          main_key: userData_cv_Value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "user got updated with the value and key") {
            get_userData_CV();
            setOpenEdit_UserDataCV_Modal(false);
            Edit_app_CV();
          } else {
            ServerError();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const delete_userData_CV = (e) => {
    fetch("https://api.trypadlock.com/profile/user_data", {
      method: "DELETE",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_uuid: locState.user_id,
        name: delete_userDataCv_Value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "user key and value got deleted") {
          get_userData_CV();
          setOpenDelete_UserDataCV_Modal(false);
          Delete_app_CV();
        } else {
          ServerError();
        }
        console.log("deleteUserCV", data);
      })
      .catch((err) => console.log(err));
  };

  const customVariableTable =
    get_userdataCV == undefined ||
    get_userdataCV == "" ||
    get_userdataCV == null ? (
      <h3 className="text-lg m-10">No Custom Variable Found</h3>
    ) : (
      get_userdataCV.map((d, idx) => (
        <tr key={idx} className={idx % 2 == 0 ? "bg-white" : "bg-gray-50"}>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
            {Object.keys(d)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
            {Object.values(d)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-xs">
            <PencilIcon
              className="w-5 h-5 cursor-pointer"
              onClick={() => {
                edit_userData_CV_Modal({
                  key: Object.keys(d).toString(),
                  value: Object.values(d).toString(),
                });
              }}
            />
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-xs">
            <TrashIcon
              className="w-5 h-5 text-red-500 cursor-pointer"
              onClick={() => {
                delete_userData_CV_Modal(Object.keys(d).toString());
              }}
            />
          </td>
        </tr>
      ))
    );

  return (
    <>
      <ProfileNav />
      <div className="p-12 pr-20 pl-20 smt:p-0">
        <p
          className="flex left-0 top-2 bg-gray-700 rounded-md w-16 h-8 text-white font-bold justify-center items-center shadow-lg cursor-pointer mb-5"
          onClick={() => {
            history.goBack();
          }}>
          back
        </p>
        <div className="border shadow-md p-10 smt:p-0 smt:pb-10 smt:pt-8 flex justify-center align-middle items-center flex-col">
          <div className="flex flex-col justify-center items-center mb-10">
            <h1 className="mb-8 text-4xl font-bold">Profile</h1>
            <div className="rounded-full w-32 h-32 border flex items-center justify-center">
              <img src={user} alt="User Profile Photo" className="w-20 h-20" />
            </div>
            <p className="text-2xl font-semibold">
              {userProfile === undefined ? "" : userProfile.first_name}{" "}
              {userProfile === undefined ? "" : userProfile.last_name}
            </p>
            <h2 style={{ color: "gray" }}>Member</h2>
            <h2 style={{ color: "gray" }}>
              {`last log in: ${
                userProfile?.last_login === "undefined"
                  ? ""
                  : userProfile?.last_login?.slice(0, 10)
              }`}
            </h2>
            <div
              onClick={() => {
                setOpenEditModel(true);
              }}
              className="mt-4 text-sm bg-gray-800 text-white p-1 px-2 rounded-xl cursor-pointer">
              Edit Profile
            </div>
          </div>
          {/* <div></div> */}

          <div className=" p-8 grid grid-cols-2 gap-4 w-full mb-8">
            {/* <div className='flex flex-col  rounded-md'>
                            <label className='mb-2 text-lg'>User Id</label>
                            <input value={userProfile=== undefined ? '' : userProfile.user_id} className='rounded-md bg-white' />
                        </div> */}
            <div className="flex flex-col rounded-md   ">
              <label className="mb-2 text-lg">User Name</label>
              <input
                disabled
                className="rounded-md bg-white"
                value={userProfile === undefined ? "" : userProfile.username}
              />
            </div>
            <div className="flex flex-col rounded-md ">
              <label className="mb-2 text-lg">First Name</label>
              <input
                disabled
                className="rounded-md bg-white"
                value={userProfile === undefined ? "" : userProfile.first_name}
              />
            </div>
            <div className="flex flex-col rounded-md ">
              <label className="mb-2 text-lg">Last Name</label>
              <input
                disabled
                className="rounded-md bg-white"
                value={userProfile === undefined ? "" : userProfile.last_name}
              />
            </div>
            <div className="flex flex-col rounded-md ">
              <label className="mb-2 text-lg">Email</label>
              <input
                disabled
                className="rounded-md bg-white"
                value={userProfile === undefined ? "" : userProfile.email}
              />
            </div>
            <div className="flex flex-col  rounded-md">
              <label className="mb-2 text-lg">Created At</label>
              <input
                disabled
                value={
                  userProfile === undefined
                    ? ""
                    : moment(userProfile.created).format("MM-DD-YYYY")
                }
                className="rounded-md bg-white"
              />
            </div>
            <div className="flex flex-col  rounded-md">
              <label className="mb-2 text-lg">Role</label>
              <input
                disabled
                value={userProfile === undefined ? "" : userProfile.roles}
                className="rounded-md bg-white"
              />
            </div>
          </div>

          <div className="w-full pr-8 pl-8">
            <div className="flex">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mr-4">
                Custom Variables
              </h3>
              <p
                className="w-9 h-6 rounded-full bg-gray-800 text-white flex justify-center items-center cursor-pointer font-bold"
                onClick={() => {
                  setOpenCreate_userDataCv_Modal(true);
                }}>
                +
              </p>
            </div>
            {GTable(customVariableTable, ["Key", "Value", "Edit", "Delete"])}
          </div>

          <div className="mt-10">
            <p className="text-2xl my-4">Magic Link</p>
            <button
              onClick={(e) => {
                handleNavigation(e, userProfile.email, "", "magicLink");
              }}
              className="bg-gray-800 text-white p-1 px-4 rounded-xl font-semibold shadow-md">
              Link Generator
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={openEditModel}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        <>
          <div className="grid grid-cols-2 content-center">
            {/* <label className="text-lg font-bold mt-7"> User Name</label>
                        <input type='text' onChange={(e)=>{setAddUserNameOrg(e.target.value)}} placeholder="UserName" className="rounded-md mt-5"/> */}
            <label className="text-lg font-bold mt-7"> First Name</label>
            <input
              type="text"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder="First Name"
              className="rounded-md mt-5"
            />
            <label className="text-lg font-bold mt-7"> Last Name</label>
            <input
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              placeholder="Last Name"
              className="rounded-md mt-5"
            />
            {/* <label className="text-lg font-bold mt-7"> First Name</label>
                        <input type='text' onChange={(e)=>{setFirstName(e.target.value)}} placeholder="First Name" className="rounded-md mt-5" value={userProfile == undefined ? "" : userProfile.first_name}/>
                        <label className="text-lg font-bold mt-7"> Last Name</label>
                        <input type='text' onChange={(e)=>{setLastName(e.target.value)}} placeholder="Last Name" className="rounded-md mt-5" value={userProfile == undefined ? "" :  userProfile.last_name}/> */}
            {/* <label className="text-lg font-bold mt-7">Email</label>
                        <input type='text' onChange={(e)=>{setEmail(e.target.value)}} placeholder="Email" className="rounded-md mt-5" value={email}/> */}
            <label className="text-lg font-bold mt-7">Password</label>
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              className="rounded-md mt-5"
            />
            <label className="text-lg font-bold mt-7">Avatar</label>
            <input
              type="file"
              onChange={(e) => {
                setAvatar(e.target.files[0]);
              }}
              placeholder="Avatar"
              className="rounded-md mt-5"
            />
            <label className="text-lg font-bold mt-7">Timezone</label>
            <TimezoneSelect
              value={timezone}
              onChange={setTimeZone}
              className="rounded-md mt-5"
            />
            <label className="text-lg font-bold mt-7">Role</label>
            <Dropdown
              options={
                locState?.appId == 50 ? strivedRolesOptions : rolesOptions
              }
              onChange={(e) => {
                handleRoleSelect(e);
              }}
              className="rounded-md mt-5"
              controlClassName="myControlClassName"
              placeholder="Role"
            />
          </div>
          <div className="flex justify-center items-center">
            <button
              className="bg-gray-800 text-white rounded-md w-28 p-2.5 mt-8 font-semibold flex justify-center items-center"
              onClick={(e) => {
                editUserInfo(e);
              }}>
              Submit
            </button>
          </div>
        </>
      </Modal>
      <Modal
        open={openEdit_userDataCV_Modal}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        <div>
          <p className="text-2xl my-5 font-bold">
            Edit Value for Custom Variable
          </p>
          <div className="flex flex-col">
            <label className="text-md font-bold">Key Name</label>
            <input
              type="text"
              disabled
              name="appKey"
              placeholder="Key"
              value={placeHolder_valueCV.key}
              className="mt-2 mb-4 rounded-md"
            />
            <label className="text-md font-bold">Value</label>
            <input
              type="text"
              onChange={(e) => {
                setUserData_cv_Value(e.target.value);
              }}
              placeholder="Value"
              className="mt-2 mb-4 rounded-md"
            />
            <button
              className="bg-gray-900 text-white rounded-md p-2.5 font-semibold"
              onClick={(e) => {
                edit_userData_CV(e);
              }}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openCreate_userDataCv_Modal}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        <div>
          <p className="text-2xl my-5 font-bold">Create Custom Variable</p>
          <div className="flex flex-col">
            <label className="text-md font-bold">Key Name</label>
            <input
              type="text"
              onChange={(e) => {
                setUserData_cv_keyValue(e.target.value);
              }}
              name="appKey"
              placeholder="Key"
              className="mt-2 mb-4 rounded-md"
            />
            <label className="text-md font-bold">Value</label>
            <input
              type="text"
              onChange={(e) => {
                setUserData_cv_Value(e.target.value);
              }}
              placeholder="Value"
              className="mt-2 mb-4 rounded-md"
            />
            <button
              className="bg-gray-900 text-white rounded-md p-2.5 font-semibold"
              onClick={(e) => {
                create_userData_CV(e);
              }}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={openDelete_userDataCV_Modal}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        <div>
          <label>Are you sure you want to Delete?</label>
          <div className="mt-8 flex justify-center items-center">
            <button
              onClick={() => {
                onCloseModel();
                setDelete_userDataCv_Value("");
              }}
              className="bg-gray-700 text-white font-bold rounded-md w-24 h-8 mr-4 ">
              Cancel
            </button>
            <button
              onClick={() => {
                delete_userData_CV();
              }}
              className="bg-red-600 text-white font-bold rounded-md w-24 h-8">
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
}
