import React, { useState } from "react";
import {
  AppAlreadyExists,
  AppCreated,
  ValidURL,
} from "../components/NotificationToast";
import { transport } from "./api";
import { api_url } from "./auth";

export default function CreateApp(appName, appURL) {
  var authToken = localStorage.getItem("token");
  fetch(`${api_url}/loginapp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Jwt " + authToken,
    },
    body: JSON.stringify({
      name: appName,
      redirect_url: appURL,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.status === "applications already found") {
        AppAlreadyExists();
      } else if (data.stats == "please enter the valid url") {
        ValidURL();
      } else {
        AppCreated();
      }
    })
    .catch((err) => console.log("Error in login POST", err));
}

export const getUserDetails = () => {
  const config = {
    method: "GET",
    url: "/loginapp/profile",
    // withCredentials: true,
  };
  return transport(config);
  // try {
  //     const response = await api.get('/loginapp/profile');
  //     setUserInfo(response.data);
  //     console.log('userPROFILERES', response.data);

  // } catch (error) {
  //     console.log(error)
  // }
};

export const getUserDataRevenue = () => {
  const config = {
    method: "GET",
    url: "/loginapp/userdata",
    // withCredentials: true
  };
  return transport(config);
};
export const getUserDataRevenueApp = (appid) => {
  const config = {
    method: "GET",
    url: "/loginapp/userdata-app?appid=" + appid,
    // withCredentials: true
  };
  return transport(config);
};

export const getUserApps = () => {
  const config = {
    method: "GET",
    url: "/loginapp",
    // withCredentials: true
  };
  return transport(config);
};

export const getOrgs = (appid) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/loginapp/orgList?appid=${appid}`,
  };
  return transport(config);
};

export const getDeactiveOrgs = (appid) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/loginapp/deactiveorgList?appid=${appid}`,
  };
  return transport(config);
};

export const getPlans = (appId) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/loginapp/pricelist?appid=${appId}`,
  };
  return transport(config);
};
export const getOwner = (orgId) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/loginapp/owner_org?org_uuid=${orgId}`,
  };
  return transport(config);
};

export const getRegionList = () => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/users/regions`,
  };
  return transport(config);
};

export const getUserListofORG = (appId, orgId) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/loginapp/orgUserList?appid=${appId}&orgid=${orgId}`,
  };
  return transport(config);
};

export const getPromptList = (org_uuid, page, limit) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/prompt/get_prompt?org_uuid=${org_uuid}&page=${page}&limit=${limit}`,
  };
  return transport(config);
};

export const getPromptGrpList = (org_uuid) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/prompt/get_promptGrp_list?org_uuid=${org_uuid}`,
  };
  return transport(config);
};

// export const getTrail

export const getRevenueOfORG = (org_uuid) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/loginapp/orgdata/?org_uuid=${org_uuid}`,
  };
  return transport(config);
};

export const getOrgActivityLog = (org_uuid) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/logs?org_uuid=${org_uuid}`,
  };
  return transport(config);
};

export const getOrgTeam = (org_uuid) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/team/listTeam/${org_uuid}`,
  };
  return transport(config);
};

export const getTeamMemebers = (teamid) => {
  const config = {
    method: "GET",
    url: `https://api.trypadlock.com/team/listMember/${teamid}`,
  };
  return transport(config);
};

export const addMembersTeam = (org_uuid, team_uuid, user_id, team_name) => {
  const config = {
    method: "PUT",
    url: `https://api.trypadlock.com/team/AddTeamMember/${org_uuid}/${team_uuid}/?userid=${user_id}&team=${team_name}`,
  };
  return transport(config);
};

export const deleteORG = (orgIDtobeDel) => {
  const config = {
    method: "PUT",
    url: `https://api.trypadlock.com/org`,
    data: {
      org_uuid: orgIDtobeDel,
      status: "false",
    },
  };
  return transport(config);
};

export const reactivateORG = (orgIDtobeRea) => {
  const config = {
    method: "PUT",
    url: `https://api.trypadlock.com/org`,
    data: {
      org_uuid: orgIDtobeRea,
      status: true,
    },
  };
  return transport(config);
};

export const updateOrgName = (org_uuid, org_name) => {
  const config = {
    method: "PUT",
    url: `https://api.trypadlock.com/org/update_org_name?org_uuid=${org_uuid}&name=${org_name}`,
  };
  return transport(config);
};

export const getOrgCV = (uuidOrg) => {
  const config = {
    method: "GET",
    url: `/org/org_data?org_uuid=${uuidOrg}`,
  };

  return transport(config);
};

export const getAppCVs = (appUuid) => {
  const config = {
    method: "GET",
    url: `/app/app_data?app_uuid=${appUuid}}`,
  };

  return transport(config);
};

export const getUserDataCVs = (user_id) => {
  const config = {
    method: "GET",
    url: `/profile/user_data?user_uuid=${user_id}`,
  };

  return transport(config);
};

export const getClintId = (appId) => {
  const config = {
    method: "GET",
    url: `/loginapp/get-clientId/${appId}`,
  };
  return transport(config);
};

export const getTemplateConfig = (appId) => {
  const config = {
    method: "GET",
    url: `/loginapp/templateconfig/${appId}`,
  };
  return transport(config);
};

export const addTemplateConfig = (formData) => {
  console.log("from data : " + formData);
  const config = {
    method: "POST",
    url: "/loginapp/templateconfig",
    data: formData,
  };

  return transport(config);
};
